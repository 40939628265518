import { products, resources } from "./content/navbar";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";

import KincodeLogo from "./../../assets/logo/kincode-logo.svg";
import Cta from "./../cta/Cta";

import { BiWorld } from "react-icons/bi";
import { IoChevronForward } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";
import { IoMenuOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { IoChevronBack } from "react-icons/io5";


export default function MobileNav() {
    const { t, i18n } = useTranslation();

    const [currentMenu, setCurrentMenu] = useState(undefined);
    const [isOverlay, setIsOverlay] = useState(false);

    let onProductClicked = () => {
        currentMenu===undefined || currentMenu===resources ? setCurrentMenu(products) : setCurrentMenu(undefined);
        setIsOpen(false);
        setIsOverlay(true);
    };

    // let onResourcesClicked = () => {
    //     currentMenu===undefined || currentMenu===products ? setCurrentMenu(resources) : setCurrentMenu(undefined);
    //     setIsOpen(false);
    //     setIsOverlay(true);
    // };

    let overlayClosed = () => {
        setIsOpen(true);
        setIsOverlay(false);
        setCurrentMenu(undefined);
    };
    
    let location = useLocation();

    useEffect(() => {
        setCurrentMenu(undefined);
    }, [location.pathname]);

    const [isOpen, setIsOpen] = useState(false);
    let onMenuClicked = () => {
        isOverlay ? setIsOpen(false) : setIsOpen(!isOpen);
        setIsOverlay(false);
        setCurrentMenu(undefined);
        document.body.classList.toggle("overflow-hidden");
    }

    const [isScroll, setIsScroll] = useState(false);
    useEffect(() => {
        setIsScroll(window.scrollY >= 56)
    }, [])

    const [currentLang, setCurrentLang] = useState(i18n.language);

    useEffect(() => {

        i18n.changeLanguage(currentLang);
    
    }, [currentLang])

    return(
        <>
            <div className="h-14 w-full bg-[#BBF7D0] flex flex-row">
                <div className="w-full max-w-screen-xl px-5 flex flex-row justify-center items-center m-auto">
                    <Cta text={t("buttons.tryKincode")} btnType="tertiary-black" iconRight={<IoChevronForward />} goTo="routes.contact" />
                </div>
            </div>

            <nav className="w-full h-20 flex flex-col sticky top-0 bg-white z-40">
                <div className="w-full max-w-screen-xl px-5 flex flex-row justify-between items-center m-auto">
                    <Link to={t("routes.home")}>
                        <img className="max-h-12" src={KincodeLogo} alt="Kincode logo" />
                    </Link>
                    <NavLink to="#" onClick={onMenuClicked}>
                        {(isOpen || isOverlay) ? <IoCloseOutline className="h-auto w-12 p-2 -mr-2" /> : <IoMenuOutline className="h-auto w-12 p-2 -mr-2" /> }
                    </NavLink>
                </div>

                {(isOpen || isOverlay) &&
                    <div className="absolute top-20 left-0 w-screen bg-black/50 z-40 flex flex-row-reverse" style={{height: isScroll ? "calc(100vh - 3.5rem)" : "calc(100vh - 5rem)"}}>
                        
                        {isOpen &&
                            <div className="flex flex-col p-5 pb-40 bg-white z-40 w-screen sm:w-96 overflow-y-scroll" style={{height: isScroll ? "calc(100vh - 3.5rem)" : "calc(100vh - 5rem)"}}>
                                <ul className="flex flex-col gap-5">
                                    <Cta text={t("navbar.menu.product")} btnType="tertiary-black" click={onProductClicked} goTo="#" iconRight={<IoChevronDownSharp />} rotateAnimation={true} isSelected={products === currentMenu} />
                                    {/* <Cta text={t("navbar.menu.resources")} btnType="tertiary-black" click={onResourcesClicked} goTo="#" iconRight={<IoChevronDownSharp />} rotateAnimation={true} isSelected={resources === currentMenu} /> */}
                                    <Cta text="navbar.menu.about" btnType="nav" goTo="routes.about" click={onMenuClicked} />
                                    <Cta text="navbar.menu.solutions" btnType="tertiary-black" goTo="routes.toxicCulture" click={onMenuClicked} />
                                    <Cta text="navbar.menu.price" btnType="tertiary-black" goTo="routes.pricing" click={onMenuClicked} />
                                    <Cta text="navbar.menu.contact" btnType="tertiary-black" goTo="routes.contact" click={onMenuClicked} />
                                </ul>

                                <div className="flex flex-col gap-5 py-10 my-5 border-y border-slate-200">
                                    <Cta text="buttons.free" btnType="secondary" goTo="routes.contact" click={onMenuClicked} />
                                    <Cta text="buttons.demo" btnType="primary" goTo="routes.contact" click={onMenuClicked} />
                                </div>

                                <div>
                                    <div className="flex flex-col justify-center items-center gap-5">
                                        <Link to="//platform.kincode.app" target="_blank">{t("buttons.login")}</Link>
                                        <label htmlFor="lang" className="flex flex-row">
                                            <BiWorld className="w-4 h-auto" />
                                            <select defaultValue={currentLang} className="bg-none border-none bg-white active:border-none focus:outline-none cursor-pointer" onChange={(e) => {setCurrentLang(e.target.value)}}>
                                                <option value="es-ES" className="bg-white">
                                                    <Cta text="navbar.translate.spanish" btnType="tertiary-black" />
                                                </option>
                                                <option value="en-US" className="bg-white">
                                                    <Cta text="navbar.translate.english" btnType="tertiary-black" />
                                                </option>
                                            </select>

                                        </label>
                                    </div>
                                </div>
                            </div>
                        }

                        {isOverlay && 
                            <div className="flex flex-col p-5 pb-40 bg-white z-40 w-screen sm:w-96 overflow-y-scroll" style={{height: isScroll ? "calc(100vh - 56px)" : "calc(100vh - 136px)"}}>
                                <div className="mb-5">
                                    <Cta text={t("buttons.back")} btnType="tertiary-black" iconLeft={<IoChevronBack />} click={overlayClosed} goTo="#" />
                                </div>
                                <div className="flex flex-col gap-10">
                                    {currentMenu?.map((product) => {
                                        return(
                                            <div className="flex flex-col">
                                                <NavLink to={t(product.goTo)} className="font-bold hover:text-[#107D35] text-left" onClick={onMenuClicked}>{t(product.title)}</NavLink>

                                                <ul className="flex flex-col">
                                                    {product.elements.map((element, index) => {
                                                        return(
                                                            <li key={index} onClick={onMenuClicked}>{element}</li>
                                                        )
                                                    })}

                                                </ul>
                                            </div>
                                        )
                                    })}
                                </div>
                                
                            </div>
                        }

                    </div>
                
                }
                
            </nav>

            

            
            
        </>
    )
}