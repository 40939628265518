import { useTranslation } from "react-i18next";

/**
 * Container with text, points and image
 * 
 * @param {object} obj {imgSrc, imgAlt, subtitle, title, description, points[{icon, description}]} 
 * @param {component} children Add components like buttons
 * @param {string} order "LTR | RTL"
 * @param {boolean} isHero "true | false"
 * @returns 
 */
export default function TextImageContainer({obj, children, order, isHero=false, isAnimated=false}) {
    const { t } = useTranslation();

    let imgStyles = "w-full md:max-w-md h-min object-cover object-center m-auto lg:max-h-[512px] overflow-hidden";
    
    if (order === "RTL") {
        imgStyles += " lg:order-2";
        
    }

    if (isHero) {
        imgStyles += " order-2 md:max-w-full lg:max-w-2xl lg:max-h-[512px]";
    }

    return(
        <div className="text-left max-w-screen-xl m-auto flex flex-col lg:flex-row sm:gap-12 lg:justify-between overflow-hidden">
            {isAnimated ?
                <picture className={imgStyles}>
                    <source 
                        srcSet={obj.altImgSrc} alt={obj.imgAlt} 
                        media="(prefers-reduced-motion: reduce)" 
                    />

                    <img 
                        src={obj.imgSrc} alt={obj.imgAlt} 
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src=obj.altImgSrc;
                        }}
                        
                    />
                </picture>

                :

                <img 
                    src={obj.imgSrc} alt={obj.imgAlt} 
                    className={imgStyles}
                />
            
            }
            

            <div className="p-5 md:my-8 w-full lg:max-w-[75ch] lg:min-w-[45ch] self-center">
                <div className="mb-8 flex flex-col gap-4">
                    {isHero ? 
                        <h1 className="font-bold">
                            <span className="text-[#107D35] text-2xl">{t(obj.subtitle)}</span><br />
                            <span className="text-5xl">{t(obj.title)}</span>
                        </h1>
                        :
                        <h2 className="font-bold">
                            <span className="text-[#107D35] text-xl">{t(obj.subtitle)}</span><br />
                            <span className="text-4xl">{t(obj.title)}</span>
                        </h2>
                    }

                    <p dangerouslySetInnerHTML={{__html: t(obj.description)}}></p>

                    {obj.points?.map((point, index) => {
                            return(
                                <div key={index} className="flex flex-row gap-4">
                                    <div className="w-8 h-8 grow-0 shrink-0 mt-1">{point.icon}</div>
                                    <p>{t(point.description)}</p>
                                </div>
                            )
                        })
                    }
                </div>

                <div>
                    {children}
                </div>
            </div>

        </div>
    )
}